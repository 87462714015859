body, #react-root, html{
  height: 100%;
}
@font-face {
  font-family: "AvertaRegular";
  src: url("./fonts/AvertaRegular.woff") format("woff");
}
@font-face {
  font-family: "AvertaBold";
  src: url("./fonts/AvertaBold.woff") format("woff");
}
a{
  text-decoration: none;
}