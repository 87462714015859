body, #react-root, html {
  height: 100%;
}

@font-face {
  font-family: AvertaRegular;
  src: url("AvertaRegular.83d4c5b9.woff") format("woff");
}

@font-face {
  font-family: AvertaBold;
  src: url("AvertaBold.224d90a8.woff") format("woff");
}

a {
  text-decoration: none;
}

/*# sourceMappingURL=index.211fa70f.css.map */
